import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from '../../../helpers';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import { Router  } from '@angular/router';

@Component({
    selector: ".m-grid__item.m-grid__item--fluid.m-grid.m-grid--ver-desktop.m-grid--desktop.m-body",
    templateUrl: "./default.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class DefaultComponent implements OnInit {
	isDashboardPage:boolean=false;
    constructor(private router: Router) {

    }
    ngOnInit() {
		if(this.router.url=="/index"){
			this.isDashboardPage=true;
		}
		
    }

}